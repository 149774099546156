
const KEY = "JWT_TOKEN";

export const set = (data, callback = () => null) => {
    const tokenData = {...data};
    localStorage.setItem(KEY, JSON.stringify(tokenData));
    callback(data);
}

export const get = () => {
    const data = localStorage.getItem(KEY);
    return data ? JSON.parse(data) : null;
}

export const remove = (callback = () => null) => {
    localStorage.removeItem(KEY);
    callback();
}