import React, {useState, useEffect} from 'react';
import httpService from "../../../services/http.service";
import AppBreadcrumb from "../../Shared/AppBreadcrumb";
import FecthingSpinner from "../../Shared/FecthingSpinner";
import MyOpinionLogo from "../../../assets/images/MyOpinion_Logo_Head.png";
import {useFormik} from "formik";
import * as Yup from 'yup';
import AppInput from "../../Shared/AppInput";
import {Form} from "reactstrap";
import useAlert from "../../../hooks/useAlert";
import AppAlert from "../../Shared/AppAlert";
import {connect} from "react-redux";
import AppCheckbox from "../../Shared/AppCheckbox";
import {extractResponseValidationErrors, SECTION_TYPE_DIRECTION, SECTION_TYPE_DEPARTMENT, SECTION_TYPE_SERVICE, SECTION_TYPE_EMPLOYER} from "../../../utils/index";
import AppSelect from "../../Shared/AppSelect";
import {Redirect} from "react-router-dom";

const ModerateurList = ({authUser, ...props}) => {

    const initialValues = {
        nom: '',
        prenom: '',
        email: '',
        password: '',
        section_type: '',
        section_name: '',
        password_confirmation: '',
        isAdmin: false,
        editMode: false,
    };

    const [fetching, setFetching] = useState(true);
    const [submitting, setSubmission] = useState(false);
    const [admins, setAdmins] = useState([]);
    const [data, setData] = useState(initialValues);
    const [modal, setModal] = useState(false);
    const [alert, setAlert, onClose] = useAlert();
    const [ddls, setDdls] = useState({
        section_types: [],
        section_names: []
    });

    const fetchAdmins = async (cb = () => null) => {
        const {data: response} = await httpService.get(`/moderateurs`);
        // setAdmins(response?.data);
        cb(response?.data);
        return response?.data;
    }

    const fetchSectionTypes = async (cb = () => null) => {
        const {data: response} = await httpService.get(`/hr/section_types`);
        cb(response?.data);
        return response?.data
    }

    const fetchSections = async (sectionType = SECTION_TYPE_DIRECTION, cb = () => null) => {

        let url = '';

        switch (sectionType) {

            case SECTION_TYPE_DIRECTION:
                url = '/hr/directions';
                break;
            case SECTION_TYPE_DEPARTMENT:
                url = '/hr/departments';
                break;
            case SECTION_TYPE_SERVICE:
                url = '/hr/services';
                break;
            case SECTION_TYPE_EMPLOYER:
                url = '/hr/employers';
                break;

        }

        const {data: response} = await httpService.get(url);
        cb(response?.data);
        return response?.data
    }

    const onSectionTypeChange = (e) => {
        const value = e.target.value;
        formik.setFieldValue('section_type', value, true);
        formik.setFieldTouched('section_type', true);


        formik.setFieldValue('section_name', '', true);
        formik.setFieldTouched('section_name', true);
        setDdls(ddls => ({
            ...ddls,
            section_names: []
        }));

        if(!value) return

        (async () => {
           const sections = await fetchSections(value);
           setDdls(ddls => ({
               ...ddls,
               section_names: sections
           }));
        })();

    }

    const onSubmit = async (values) => {
        setSubmission(true);
        setAlert(null);

        const link = `${window.location.protocol}//${window.location.hostname}`

        const formData = {
            ...values,
            link
        };

        try {
            if(values.editMode) {
                const {data: response} = await httpService.put(`/moderateurs/${values.id}`, formData);

                const newArr = admins.map(admin => {
                    if(admin.id == values.id) {
                        return {
                            ...response.data
                        }
                    }

                    return admin;
                });

                setAdmins(newArr);
            }
            else {
                const {data: response} = await httpService.post('/moderateurs', formData);

                setAdmins((state) => [
                    ...admins,
                    response.data
                ]);
            }

            onCancel();

        } catch ({response}) {
            const {data, status} = response;

            if(response && status == 422) {
                setAlert({
                    type: 'danger',
                    message: extractResponseValidationErrors(data)[0]
                })
            }
            else if(response && status == 500) {
                setAlert({
                    type: 'danger',
                    message: 'Erreur de traitement, veuillez contacter les administrateurs'
                })
            }
        } finally {
            setSubmission(false);
        }
    }

    const onEditAdmin = (admin) => {

        (async () => {
            if(admin?.section_type) {
                const sections = await fetchSections(admin.section_type);
                setDdls(state => ({
                    ...state,
                    section_names: sections
                }))
            }
        })()

        setData({
            ...admin,
            editMode: true
        });
        setModal(true);
    }

    const onToggle = async (admin) => {

        let message = '';

        if(admin?.actif) {
            message = 'Confirmer désactivation du compte ?';
        }
        else {
            message = 'Confirmer activation du compte ?';
        }

        if(!window.confirm(message)) return;

        try {

            await httpService.put(`/moderateurs/${admin.id}/toggle`);

            const newArr = admins.map(obj => {
                if(obj.id == admin.id) {

                    return {
                        ...admin,
                        actif: !admin.actif
                    }

                }
                return obj;
            });

            setAdmins(newArr);

        } catch (e) {
            throw e;
        }
    }

    const onDelete = async (admin) => {
        if(!window.confirm('Confirmer suppression du compte ?')) return;

        try {
            await httpService.delete(`/moderateurs/${admin.id}`);

            const newArr = admins.filter(obj => obj.id != admin.id);

            setAdmins(newArr);

        } catch (e) {
            throw e;
        }
    }

    const onAddAdmin = () => {
        setData(initialValues);
        setModal(true);
    }

    const onCancel = () => {
        formik.resetForm(initialValues);
        setData(initialValues);
        setAlert(null);
        setModal(false);
    }

    const resetForm = () => {}

    useEffect(() => {
        (async () => {
           const admins = await fetchAdmins();
           const section_types = await fetchSectionTypes();
            setAdmins(admins)
            setDdls(state => ({
                ...state,
                section_types
            }));

            setFetching(false);
        })()
    }, []);

    useEffect(() => {

        formik.setValues({
            ...initialValues,
            ...data,
        });

    }, [data]);

    const editMode = !!data?.id;

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object().shape({
            nom: Yup.string().ensure().required('Champ obligatoire'),
            prenom: Yup.string().ensure().required('Champ obligatoire'),
            email: Yup.string().email('Format email invalide').ensure().required('Champ obligatoire'),
            password: Yup.string().ensure().nullable().when('editMode', {
                is: (editMode) => editMode == false,
                then: Yup.string().ensure().required('Champ obligatoire').min(6, 'Minimun 6 carateres')
            }),
            password_confirmation: Yup.string().ensure().nullable().when(['editMode', 'password'], {
                is: (editMode, password) => editMode == false || password,
                then: Yup.string().ensure().oneOf([
                    Yup.ref('password')
                ], 'Le mot de passe ne correspond pas').required('Champ obligatoire')
            }),
            section_type: Yup.string().ensure().nullable(),
            section_name: Yup.string().ensure().when(
                [],
                {
                    is: () => formik.values.section_type,
                    then: Yup.string().ensure().required('Champ obligatoire'),
                    otherwise: Yup.string().ensure().nullable()
                }
            ),
        }),
        onSubmit
    });

    if(!authUser.isRoot) {
        return <Redirect to="/unauthorize" />
    }

    return (
        <div className="container-fluid">
            <AppBreadcrumb title="Modérateurs">
                <div className="col-md-6 col-sm-12 text-right">
                    <button className="btn btn-sm btn-primary font-weight-bold" onClick={onAddAdmin}>
                        <i className="fa fa-plus"></i>{' '}
                        <span>Nouveau modérateur</span>
                    </button>
                </div>
            </AppBreadcrumb>

            {fetching ? <FecthingSpinner /> : (
                <div className="row clearfix">
                    <div className="col-lg-12">
                        <div className="table-responsive">
                            <table className="table table-hover table-custom spacing5">
                                <tbody>
                                {admins.map(admin => (
                                    <tr key={admin.id}>
                                        <td className="w60">
                                            <img src={admin.photo || MyOpinionLogo} data-toggle="tooltip" data-placement="top" title="" alt="Avatar" className="w35 rounded" data-original-title="Avatar Name" />
                                        </td>
                                        <td>
                                            <span className="font-weight-bold">{admin.nom} {admin.prenom}</span>
                                        </td>
                                        <td>
                                            <span>{admin.email}</span>
                                        </td>
                                        <td>
                                            {admin?.isRoot ? (
                                                <span className="badge badge-danger">Super administrateur</span>
                                            ) : (
                                                <>
                                                    {admin.isAdmin ? (
                                                        <span className="badge badge-warning">Adminsitrateur</span>
                                                    ) : (
                                                        <span className="badge badge-default">Modérateur</span>
                                                    )}
                                                </>
                                            )}

                                        </td>
                                        <td>
                                            {admin.actif ? (
                                                <span className="badge badge-success">Actif</span>
                                            ) : (
                                                <span className="badge badge-danger">Inactif</span>
                                            )}
                                        </td>
                                        {authUser.isRoot && (
                                            <>
                                                <td>
                                                    {authUser.id != admin.id && (
                                                        <>
                                                            <button className="btn btn-primary btn-sm font-weight-bold mr-1"
                                                                    onClick={() => onEditAdmin(admin)}>
                                                                <i className="fa fa-edit"></i>{' '}
                                                            </button>
                                                            {admin.actif ? (
                                                                <button className="btn btn-warning btn-sm font-weight-bold mr-1"
                                                                        onClick={() => onToggle(admin)}>
                                                                    <i className="fa fa-exclamation"></i>{' '}
                                                                </button>
                                                            ) : (
                                                                <button className="btn btn-success btn-sm font-weight-bold mr-1"
                                                                        onClick={() => onToggle(admin)}>
                                                                    <i className="fa fa-check"></i>{' '}
                                                                </button>
                                                            )}
                                                            <button className="btn btn-danger btn-sm font-weight-bold"
                                                                    onClick={() => onDelete(admin)}>
                                                                <i className="fa fa-trash"></i>{' '}
                                                            </button>
                                                        </>
                                                    )}
                                                </td>
                                            </>
                                        )}
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}

            <div className={`modal fade ${modal ? 'd-block show' : ''}`} id="exampleModal"  onClick={() => null}>
                <div className="modal-dialog modal-lg" role="document">
                    <Form onSubmit={formik.handleSubmit}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{editMode ? 'Édition' : 'Nouvel administrateur'}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCancel}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {alert && <AppAlert onClose={onClose} type={alert.type}>{alert.message}</AppAlert>}
                                <div className="row">
                                    <div className="col-md-6">
                                        <AppInput
                                            name="nom"
                                            label="Nom"
                                            value={formik.values.nom}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.errors.nom}
                                            touched={formik.touched.nom}
                                            required
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <AppInput
                                            name="prenom"
                                            label="Prénom"
                                            value={formik.values.prenom}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.errors.prenom}
                                            touched={formik.touched.prenom}
                                            required
                                        />
                                    </div>
                                </div>
                                <AppInput
                                    name="email"
                                    label="Adresse email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.errors.email}
                                    touched={formik.touched.email}
                                    required
                                />
                                <div className="form-group">
                                    <AppCheckbox
                                        name="isAdmin"
                                        label="Administrateur ?"
                                        onChange={(e) => formik.setFieldValue('isAdmin', e.target.checked)}
                                        checked={formik.values.isAdmin}
                                    />
                                </div>
                                {(process.env.REACT_APP_SOCIAL_HUB && !formik.values.isAdmin) && (
                                    <div className="row">
                                        <div className="col-md-6">
                                            <AppSelect
                                                name="section_type"
                                                label="Type de section"
                                                value={formik.values.section_type}
                                                error={formik.errors.section_type}
                                                touched={formik.touched.section_type}
                                                onChange={onSectionTypeChange}
                                                onBlur={formik.handleBlur}
                                            >
                                                {ddls.section_types.map(section => (
                                                    <option key={section?.value} value={section?.value}>{section?.label_fr}</option>
                                                ))}
                                            </AppSelect>
                                        </div>
                                        <div className="col-md-6">
                                            <AppSelect
                                                name="section_name"
                                                label="Section"
                                                value={formik.values.section_name}
                                                error={formik.errors.section_name}
                                                touched={formik.touched.section_name}
                                                required={formik.values.section_type}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            >
                                                {ddls.section_names.map(section => (
                                                    <option key={section?.value} value={section?.value}>{section?.value}</option>
                                                ))}
                                            </AppSelect>
                                        </div>
                                    </div>
                                )}
                                <hr/>
                                <AppInput
                                    type="password"
                                    name="password"
                                    label="Nouveau mot de passe"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.errors.password}
                                    touched={formik.touched.password}
                                    required={!editMode}
                                />
                                <AppInput
                                    type="password"
                                    name="password_confirmation"
                                    label="Confirmation du mot de passe"
                                    value={formik.values.password_confirmation}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.errors.password_confirmation}
                                    touched={formik.touched.password_confirmation}
                                    required={!editMode}
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className={`btn btn-round btn-danger font-weight-bold`} disabled={submitting} data-dismiss="modal" onClick={onCancel}>
                                    <span className="fa fa-times"></span>{' '}Annuler
                                </button>
                                <button type="submit" className={`btn btn-round btn-success font-weight-bold`} disabled={submitting}>
                                    <span className="fa fa-save"></span>{' '}{submitting ? 'Encour...' : 'Enregistrer'}
                                </button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    authUser: state.auth.user
})

const mapDispatchToProps = (dispatch) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(ModerateurList);