import React, {useContext, useEffect} from 'react';
import { connect } from 'react-redux';
import MetisMenu from 'react-metismenu';
import { Link } from 'react-router-dom';

import { setOffcanvas } from '../../actions/settingsAction';
import metisMenu from './metisMenu';
import DefaultLink from './DefaultLink';
import { Dropdown } from 'react-bootstrap';
import { setMiniSidebarMenuOn, setMiniHover } from '../../actions/settingsAction';
import MyOpinionLogo from '../../assets/images/MyOpinion_Logo_Head.png';
import {truncate} from "../../utils";


const Menu = ({user, ...props}) => {

	const toggleSubMenu = (e) => {
		let menucClass = ''
		if (e.itemId) {
			const subClass = e.items.map((menuItem) => {
				if (menuItem.id === 'main' || menuItem.id === 'app' || menuItem.id === 'extra' || menuItem.id === 'ui') {
					menucClass = "header";
				}
				if (menuItem.to === props.location.pathname) {
					menucClass = "";
				} else {
					menucClass = "collapse";
				}
				return menucClass;
			})
			return subClass
			// return "collapse";
		} else {
			return e.visible ? "collapse" : "metismenu";
		}
	}

	const minisidebarMouseOver = () => {
		props.setMiniSidebarMenuOn(false);
		props.setMiniHover(true);
	}

	const minisidebarMouseOut = () => {
		props.setMiniSidebarMenuOn(true);
		props.setMiniHover(false);
	}

	useEffect(() => {
		const getLitext = document.querySelectorAll('li');
		getLitext.forEach(function(el) {
			if (el.innerText === 'Main' || el.innerText === "App" || el.innerText === "UI Elements" || el.innerText === "Extra") {
				el.className = "header"
			}
		});
	}, []);

	if (document.getElementById('left-sidebar') && props.miniSidebar) {
		document.getElementById('left-sidebar').addEventListener('mouseover', minisidebarMouseOver);
	}
	if (document.getElementById('left-sidebar') && props.miniHover) {
		document.getElementById('left-sidebar').addEventListener('mouseout', minisidebarMouseOut);
	}

	return (
		<>
			<div id="left-sidebar" className={`sidebar${props.miniSideMenuOn ? ' mini_sidebar_on' : ''}`}>
				<div className="navbar-brand">
					<Link to="/">
						<img src={MyOpinionLogo} alt={process.env.REACT_APP_APP_NAME} className="img-fluid logo" />
						<span>{process.env.REACT_APP_APP_NAME}</span>
					</Link>
					<button type="button" className="btn-toggle-offcanvas btn btn-sm float-right" onClick={() => props.setOffcanvas(!props.offcanvas)}>
						<i className="lnr lnr-menu icon-close"></i>
					</button>
				</div>
				<div className="sidebar-scroll">
					<div className="user-account d-flex ">
						<div className="user_div">
							<img src={MyOpinionLogo} className="user-photo" alt="MyOpinion" />
						</div>
						<div className="dropdown">
							<span>{`${user.nom} ${user.prenom}`}</span>
							<br />
							<Dropdown className="ml-0">
								<Dropdown.Toggle variant="success" id="dropdown-basic" className="user-name left_dropdown_btn">
									<strong>{truncate(user.email, 18)}</strong>
								</Dropdown.Toggle>

								<Dropdown.Menu>
									<Link className="dropdown-item" to="/profile"><i className="icon-user"></i>Mon profil</Link>
									<li className="divider" />
									<Link className="dropdown-item" to="/login"><i className="icon-power"></i>Logout</Link>
								</Dropdown.Menu>
							</Dropdown>
							<ul className="dropdown-menu dropdown-menu-right account vivify flipInY">

							</ul>
						</div>
					</div>
					<nav id="left-sidebar-nav" className="sidebar-nav">
						<MetisMenu
							content={metisMenu}
							noBuiltInClassNames={true}
							classNameItemActive="active"
							classNameContainer={(e) => toggleSubMenu(e)}
							classNameContainerVisible="in"
							iconNamePrefix=""
							LinkComponent={(e) => <DefaultLink itemProps={e} />}
						/>
					</nav>
				</div>
			</div>

		</>
	)
}

const mapStateToProps = state => ({
	offcanvas: state.settings.offcanvas,
	miniSidebar: state.settings.miniSidebar,
	miniSideMenuOn: state.settings.miniSideMenuOn,
	miniHover: state.settings.miniHover,
	user: state.auth.user
})

const mapDispatchToProps = dispatch => ({
	setOffcanvas: (e) => dispatch(setOffcanvas(e)),
	setMiniSidebarMenuOn: (e) => dispatch(setMiniSidebarMenuOn(e)),
	setMiniHover: (e) => dispatch(setMiniHover(e)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Menu);