import React from 'react';

const ReportDetails = () => {

    return (
        <>
        </>
    );

}

export default ReportDetails;