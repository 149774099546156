import React, { useEffect, useState } from 'react';
import * as queryString from "query-string";
import {useFormik} from 'formik';
import moment from "moment";
import AppBreadcrumb from "../../Shared/AppBreadcrumb";
import httpService from "../../../services/http.service";
import FecthingSpinner from "../../Shared/FecthingSpinner";
import AppEventItem from "../../Shared/AppEventItem";
import AppPagination from "../../Shared/AppPagination";
import AppDatepicker from "../../Shared/AppDatePicker";

const EventList = ({history, match, location,...props}) => {

    const query = queryString.parse(location.search);

    const initialValues = {
        event_start_at: query?.event_start_at ? moment(query?.event_start_at, 'DD-MM-YYYY').toDate() : '',
        event_end_at: query?.event_end_at ? moment(query?.event_end_at, 'DD-MM-YYYY').toDate() : '',
    };

    const [events, setEvents] = useState([]);
    const [fetching, setFetching] = useState(true);

    const [paginationData, setPaginationData] = useState({
        currentPage:  query?.page || 1,
        lastPage: Infinity,
        hasNext: true,
        hasPrevious: false,
        totalItems: Infinity
    });

    const handlePagePaginate = async (direction) => {

        let page = parseInt(query?.page || 1);

        const nextPage = direction === 'previous' ? --page : ++page;

        const data = {
            page: nextPage,
        };

        history.push(`/events?${queryString.stringify(data)}`);

    };

    const fetchEvents = async (params = {}, cb = () => null) => {
        const { data: response } = await httpService.get(`/events`, {
            params
        });
        setEvents(response.data);
        setPaginationData(state => ({
            ...state,
            currentPage: response?.meta?.current_page,
            lastPage: response?.meta?.last_page,
            totalItems: response?.meta?.total,
            hasNext: !!response?.links?.next,
            hasPrevious: !!response?.links?.prev
        }));
        cb();
    }

    const onReset = () => {
        formik.resetForm({
            event_start_at: '',
            event_end_at: '',
        });

        history.push(`/events`);
    };

    const onSubmit = async (values) => {

        const queryObj = {
            ...initialValues,
            event_start_at: values.event_start_at ? moment(values.event_start_at).format('DD-MM-YYYY') : '',
            event_end_at: values.event_end_at ? moment(values.event_end_at).format('DD-MM-YYYY') : '',
            page: 1
        };

        history.push(`/events?${queryString.stringify(queryObj)}`);

    };

    useEffect(() => {

        setFetching(true);

        try {

            fetchEvents({
                page: query?.page || 1,
                event_start_at: query?.event_start_at,
                event_end_at: query?.event_end_at,
            }, () => setFetching(false))

        } catch (e) {
            throw e;
        }

    }, [
        query?.page,
        query?.event_start_at,
        query?.event_end_at,
    ]);

    const formik = useFormik({
        initialValues,
        onSubmit
    });

    return (
        <div className="container-fluid">
            <AppBreadcrumb title="Evénement à venir" />

            <div className="row clearfix">
                <div className="col-md-12">
                    <div className="card border shadow-sm">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="card-header bg-info text-white font-weight-bold">
                                <i className="fa fa-search"></i>{' '}
                                <span>Filtre de recherche</span>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <AppDatepicker
                                            placeholder="Debut période"
                                            className="form-control"
                                            error={formik.errors.event_start_at}
                                            touched={formik.touched.event_start_at}
                                            value={formik.values.event_start_at}
                                            onChange={(date) => formik.setFieldValue('event_start_at', date)}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <AppDatepicker
                                            placeholder="Fin période"
                                            className="form-control"
                                            error={formik.errors.event_end_at}
                                            touched={formik.touched.event_end_at}
                                            value={formik.values.event_end_at}
                                            onChange={(date) => formik.setFieldValue('event_end_at', date)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer bg-white text-right">
                                <button type="button" onClick={onReset} className="btn btn-danger mr-1">
                                    <i className="fa fa-times"></i>{' '}
                                    <span className="font-weight-bold">Annuler</span>
                                </button>
                                <button type="submit" className="btn btn-success">
                                    <i className="fa fa-check"></i>{' '}
                                    <span className="font-weight-bold">Rechercher</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {fetching ? <FecthingSpinner /> : (
                <div className="row clearfix">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="body">
                                <div className="timeline">
                                    {events.map(event => (
                                        <AppEventItem
                                            event={event}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="card">
                            <div className="body">
                                <AppPagination
                                    onNavigate={handlePagePaginate}
                                    currentPage={paginationData.currentPage}
                                    hasNext={paginationData.hasNext}
                                    hasPrevious={paginationData.hasPrevious}
                                    totalItems={paginationData.totalItems}
                                    lastPage={paginationData.lastPage}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );

};

export default EventList