import React, {useEffect, useState} from 'react';
import useAlert from "../../../hooks/useAlert";
import httpService from "../../../services/http.service";
import FecthingSpinner from "../../Shared/FecthingSpinner";
import AppBreadcrumb from "../../Shared/AppBreadcrumb";
import {Link} from "react-router-dom";
import MyOpinionLogo from "../../../assets/images/MyOpinion_Logo_Head.png";
import {Form} from "reactstrap";
import AppAlert from "../../Shared/AppAlert";
import {useFormik} from "formik";
import * as yup from 'yup';
import {extractResponseValidationErrors} from "../../../utils/index";
import AppSelect from "../../Shared/AppSelect";
import AppDatePicker from "../../Shared/AppDatePicker";
import AppInput from "../../Shared/AppInput";

const VerificationDemandDetails = ({history, location, match, ...props}) => {

    const [verification, setVerification] = useState(null);
    const [fetching, setFetching] = useState(true);
    const [alert, setAlert, onClose] = useAlert();
    const [submitting, setSubmitting] = useState(false);
    const [modal, setModal] = useState({
        id_card: false,
        rejet: false,
    });
    const [motifsRefus, setMotifsRefus] = useState([]);
    const [ddls, setDdls] = useState({
        ipCountries: [],
        idCardTypes: [],
    });

    const initialValues = {
        verif_demand_fail_reasons: []
    };

    const idCardInitialValues = {
        id_card_type_id: '',
        id_card_country_id: '',
        id_card_number: '',
        id_card_expires_at: '',
    };

    const fetchVerification = async (id, cb = () => null) => {
        const { data: response} = await httpService.get(`verifications/${id}`);
        setVerification(response.data);
        cb();
        return response;
    };

    const fetchMotifsRefus = async (cb = () => null) => {
        const { data: response} = await httpService.get(`verifications/motif_refus`);
        cb(response);
        return response;
    };

    const fetchIpCountries = async (cb = () => null) => {
        const {data: response} = await httpService.get(`/ip_countries`);
        cb(response);
        return response;
    };

    const fetchIDCartTypes = async (cb = () => null) => {
        const {data: response} = await httpService.get(`/id_card_types`);
        cb(response);
        return response;
    };

    const onSubmit = async (values) => {

        setSubmitting(true);
        setAlert(null);

        try {
            const { data: response } = await httpService.put(`/verifications/${match.params.id}/refuser`, values);
            setVerification(response?.data);
            onCancel();
        } catch ({response}) {

            const {data, status} = response;

            if(response && status === 422) {
                setAlert({
                    type: 'danger',
                    message: extractResponseValidationErrors(data)[0]
                })
            }
            else if(response && status === 500) {
                setAlert({
                    type: 'danger',
                    message: 'Erreur de traitement, veuillez contacter les administrateurs'
                });
            }

        }
        finally {
            setSubmitting(false);
        }

    };

    const onSubmitIDCardForm = async (values) => {

        setSubmitting(true);
        setAlert(null);

        try {
            const { data: response } = await httpService.put(`/verifications/${match.params.id}/valider`, values);
            setVerification(response?.data);
            onCancel();
        } catch ({response}) {

            const {data, status} = response;

            if(response && status === 422) {
                setAlert({
                    type: 'danger',
                    message: extractResponseValidationErrors(data)[0]
                })
            }
            else if(response && status === 500) {
                setAlert({
                    type: 'danger',
                    message: 'Erreur de traitement, veuillez contacter les administrateurs'
                });
            }

        }
        finally {
            setSubmitting(false);
        }

    };

    const handleValidate = async () => {
        if(!window.confirm('Cette action est irreversible, confirmer ?')) {
            return;
        }

        const { data: response} = await httpService.put(`verifications/${verification?.verif_demand_id}/valider`);
        setVerification(response.data);
    }

    const handleMotifCheck = (e) => {
        const {value, checked} = e?.target;

        let motifs = [...formik?.values?.verif_demand_fail_reasons] || []

        if(motifs.includes(value)) {
            motifs = motifs.filter(motif => motif !== value);
        }
        else {
            motifs.push(value);
        }

        formik.setFieldValue('verif_demand_fail_reasons', motifs);
    };

    const isMotifChecked = (motif) => {
        return formik?.values?.verif_demand_fail_reasons.includes(motif);
    };

    const onCancel = () => {
        formik.resetForm(initialValues);
        idCardFormik.resetForm(idCardInitialValues);
        setAlert(null);
        setModal(state => ({
            ...state,
            id_card: false,
            rejet: false
        }));
    }

    useEffect(() => {

        try {
            fetchVerification(match.params.id, async () => {
                const motifRefus = await fetchMotifsRefus();
                const ipCountries = await fetchIpCountries();
                const idCardTypes = await fetchIDCartTypes();

                setDdls(state => ({
                    ...state,
                    ipCountries,
                    idCardTypes
                }));

                setMotifsRefus(motifRefus);

                setFetching(false);
            });
        } catch (e) {
            throw e;
        }

    }, []);

    const formik = useFormik({
        initialValues,
        validationSchema: yup.object().shape({
            verif_demand_fail_reasons: yup.array().min(1, 'Veuillez selectionner au moins un motif')
        }),
        onSubmit
    });

    const idCardFormik = useFormik({
        initialValues: idCardInitialValues,
        validationSchema: yup.object().shape({
            id_card_type_id: yup.string().ensure().required('Champ obligatoire'),
            id_card_country_id: yup.string().ensure().required('Champ obligatoire'),
            id_card_number: yup.string().ensure().required('Champ obligatoire'),
            id_card_expires_at: yup.string().ensure().required('Champ obligatoire'),
        }),
        onSubmit: onSubmitIDCardForm
    });

    if(fetching) {
        return <FecthingSpinner />
    }

    return (
        <div className="container-fluid">
            <AppBreadcrumb title={`Verification de profil utilisateur: ${verification?.user?.user_surname} ${verification?.user?.user_name} (@${verification?.user?.user_username})`}>
                <div className="col-md-6 col-sm-12 text-right hidden-xs">
                    <Link to={'/verifications'}>
                        <button className="btn btn-sm btn-default">
                            <i className="fa fa-arrow-left"></i>{' '}
                            <span>Retour</span>
                        </button>
                    </Link>
                </div>
            </AppBreadcrumb>

            <div className="row clearfix">
                <div className="col-md-12">
                    <div className="card social">
                        <div className="profile-header d-flex justify-content-between justify-content-center">
                            <div className="d-flex">
                                <div className="mr-3">
                                    <img
                                        className="rounded"
                                        src={verification?.user?.profile?.prof_picture || MyOpinionLogo}
                                        height="128px"
                                        width="128px"
                                        alt="Avatar" />
                                </div>
                                <div className="details">
                                    <h5 className="mb-0">
                                        {`${verification?.user?.user_surname} ${verification?.user?.user_name}`}{' '}
                                        {verification?.user?.user_verified && (
                                            <i style={{cursor: 'pointer'}} title="Verified" className="fa fa-check-circle-o text-primary mr-1"/>
                                        )}
                                        {verification?.user?.user_gold && (
                                            <i style={{cursor: 'pointer'}} title="Premium / Gold" className="fa fa-star text-warning mr-1"/>
                                        )}
                                    </h5>
                                    <span className="text-light">@{verification?.user?.user_username}</span>
                                    <p className="mb-0">
                                        <span>Pays: <strong>{verification?.user?.user_ip_pays || '-'}</strong></span>{' '}
                                        <br/>
                                        <span>Ville: <strong>{verification?.user?.user_ville || '-'}</strong></span>{' '}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="card">
                        <div className="body">
                            <div className="row">
                                <div className="col-md-6">
                                    <small className="text-muted">Identifiant</small>
                                    <p>#{verification.verif_demand_id}</p>
                                    <small className="text-muted">Date de soumission</small>
                                    <p>{verification.verif_demand_submitted_at}</p>
                                    <small className="text-muted">Statut de la demande</small>
                                    <p>
                                        {verification?.verif_demand_status === 'Pending' && (
                                            <span className="badge badge-warning  ml-0 mr-0">En attente</span>
                                        )}
                                        {verification?.verif_demand_status === 'Success' && (
                                            <span className="badge badge-success  ml-0 mr-0">Validée</span>
                                        )}
                                        {verification?.verif_demand_status === 'Failed' && (
                                            <span className="badge badge-danger  ml-0 mr-0">Refusée</span>
                                        )}
                                    </p>
                                    {verification?.verif_demand_status !== 'Pending' && (
                                        <>
                                            <small className="text-muted">Date de traitement</small>
                                            <p>{verification.verif_demand_date_status || '-'}</p>
                                            {verification?.verif_demand_status === 'Failed' && (
                                                <>
                                                    <small className="text-muted">Motif de rejet</small>
                                                    <p>{(verification.verif_demand_fail_reasons || []).join(', ')}</p>
                                                </>
                                            )}
                                        </>
                                    )}

                                </div>
                                <div className="col-md-6">
                                    <small className="text-muted">Nom</small>
                                    <p>{verification.verif_demand_user_surname}</p>
                                    <small className="text-muted">Prénom</small>
                                    <p>{verification.verif_demand_user_name}</p>
                                    <small className="text-muted">Date de naissance</small>
                                    <p>{verification.verif_demand_user_date_of_birth}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <small className="text-muted">
                                        <i className="fa fa-facebook"></i>{' '}
                                        <span>Facebook</span>
                                    </small>
                                    <p>{verification.verif_demand_datas?.facebook_profile || '-'}</p>
                                    <small className="text-muted">
                                        <i className="fa fa-instagram"></i>{' '}
                                        <span>Instagram</span>
                                    </small>
                                    <p>{verification.verif_demand_datas?.instagram_profile || '-'}</p>
                                    <small className="text-muted">
                                        <i className="fa fa-twitter"></i>{' '}
                                        <span>Twitter</span>
                                    </small>
                                    <p>{verification.verif_demand_datas?.twitter_profile || '-'}</p>
                                </div>
                                <div className="col-md-6">
                                    <small className="text-muted">
                                        <i className="fa fa-linkedin"></i>{' '}
                                        <span>LinkedIn</span>
                                    </small>
                                    <p>{verification.verif_demand_datas?.linkedin_profile || '-'}</p>
                                    <small className="text-muted">
                                        <span className="fa fa-snapchat"></span>{' '}
                                        <span>Snapchat</span>
                                    </small>
                                    <p>{verification.verif_demand_datas?.snapchat_profile || '-'}</p>
                                    <small className="text-muted">
                                        <i className="fa fa-globe"></i>{' '}
                                        <span>Wikipedia</span>
                                    </small>
                                    <p>{verification.verif_demand_datas?.wikipedia_page || '-'}</p>
                                </div>
                            </div>

                            {verification?.id_card && (
                                <div className="row p-2 bg-warning my-2" style={{borderRadius: '5px'}}>
                                    <div className="col-md-6">
                                        <div>
                                            <small className="text-muted">
                                                <span>Pays</span>
                                            </small>
                                            <p>{verification?.id_card?.country?.ip_country_name || '-'}</p>
                                        </div>
                                        <div>
                                            <small className="text-muted">
                                                <span>Type de pièce</span>
                                            </small>
                                            <p>{verification?.id_card?.type?.id_card_type_name || '-'}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div>
                                            <small className="text-muted">
                                                <span>Numéro</span>
                                            </small>
                                            <p>{verification?.id_card?.id_card_number || '-'}</p>
                                        </div>
                                        <div>
                                            <small className="text-muted">
                                                <span>Date d'expiration</span>
                                            </small>
                                            <p>{verification?.id_card?.id_card_expires_at || '-'}</p>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div id="lightgallery" className="row clearfix lightGallery">
                                {verification?.attachments?.map(attachment => (
                                    <div className="col-lg-3 col-md-6 m-b-30" key={attachment?.attach_id}>
                                        <a className="light-link" href={attachment?.attach_url} target="_blank">
                                            <img className="img-fluid rounded" src={attachment?.attach_url} alt="" />
                                        </a>
                                    </div>
                                ))}
                            </div>
                            {verification?.verif_demand_status === 'Pending' && (
                                <div className="row">
                                    <div className="col-md-6">
                                        <button className="btn btn-success btn-block btn-round font-weight-bold" type="button" onClick={() => setModal(state => ({...state, id_card: true}))}>
                                            <i className="fa fa-check"></i>{' '}
                                            <span>Valider la vérification</span>
                                        </button>
                                    </div>
                                    <div className="col-md-6">
                                        <button className="btn btn-danger btn-block btn-round font-weight-bold" type="button" onClick={() => setModal(state => ({...state, rejet: true}))}>
                                            <i className="fa fa-times"></i>{' '}
                                            <span>Refuser la vérification</span>
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className={`modal fade ${modal.rejet ? 'd-block show' : ''}`} id="exampleModal"  onClick={() => null}>
                <div className="modal-dialog modal-md" role="document">
                    <Form onSubmit={formik.handleSubmit}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Refuser la vérification</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCancel}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {alert && <AppAlert onClose={onClose} type={alert.type}>{alert.message}</AppAlert>}
                                <div className="row">
                                    {motifsRefus.map((motif, index) => (
                                        <div className="col-md-12" key={index}>
                                            <div className="fancy-checkbox">
                                                <label>
                                                    <input
                                                        value={motif}
                                                        onChange={handleMotifCheck}
                                                        type="checkbox"
                                                        checked={isMotifChecked(motif)}
                                                    />
                                                    <span>{motif}</span>
                                                </label>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {formik?.errors?.verif_demand_fail_reasons && (
                                    <span className="font-weight-bold text-danger">
                                        {formik?.errors?.verif_demand_fail_reasons}
                                    </span>
                                )}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className={`btn btn-round btn-danger font-weight-bold`} disabled={submitting} data-dismiss="modal" onClick={onCancel}>
                                    <span className="fa fa-times"></span>{' '}Annuler
                                </button>
                                <button type="submit" className={`btn btn-round btn-success font-weight-bold`} disabled={submitting}>
                                    <span className="fa fa-save"></span>{' '}{submitting ? 'Encour...' : 'Soumettre'}
                                </button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>

            <div className={`modal fade ${modal.id_card ? 'd-block show' : ''}`} id="exampleModal"  onClick={() => null}>
                <div className="modal-dialog modal-md" role="document">
                    <Form onSubmit={idCardFormik.handleSubmit}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Validation la vérification</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCancel}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {alert && <AppAlert onClose={onClose} type={alert.type}>{alert.message}</AppAlert>}
                                <AppSelect
                                    name="id_card_country_id"
                                    label="Pays"
                                    placeholder="Selectionnez le pays"
                                    value={idCardFormik.values.id_card_country_id}
                                    onChange={idCardFormik.handleChange}
                                    onBlur={idCardFormik.handleBlur}
                                    error={idCardFormik.errors.id_card_country_id}
                                    touched={idCardFormik.touched.id_card_country_id}
                                    required
                                >
                                    {ddls.ipCountries.map(obj => (
                                        <option key={obj?.ip_country_id} value={obj?.ip_country_id}>{obj?.ip_country_name}</option>
                                    ))}
                                </AppSelect>
                                <AppSelect
                                    name="id_card_type_id"
                                    label="Type de piece"
                                    placeholder="Selectionnez le type de pièce"
                                    value={idCardFormik.values.id_card_type_id}
                                    onChange={idCardFormik.handleChange}
                                    onBlur={idCardFormik.handleBlur}
                                    error={idCardFormik.errors.id_card_type_id}
                                    touched={idCardFormik.touched.id_card_type_id}
                                    required
                                >
                                    {ddls.idCardTypes.map(obj => (
                                        <option key={obj?.id_card_type_id} value={obj?.id_card_type_id}>{obj?.id_card_type_name}</option>
                                    ))}
                                </AppSelect>
                                <AppInput
                                    label="Numero de la pièce"
                                    name="id_card_number"
                                    value={idCardFormik.values.id_card_number}
                                    onChange={idCardFormik.handleChange}
                                    onBlur={idCardFormik.handleBlur}
                                    error={idCardFormik.errors.id_card_number}
                                    touched={idCardFormik.touched.id_card_number}
                                    required
                                />
                                <AppDatePicker
                                    label="Date d'expiration"
                                    className="form-control"
                                    error={idCardFormik.errors.id_card_expires_at}
                                    touched={idCardFormik.touched.id_card_expires_at}
                                    value={idCardFormik.values.id_card_expires_at}
                                    onChange={(date) => idCardFormik.setFieldValue('id_card_expires_at', date)}
                                    required
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className={`btn btn-round btn-danger font-weight-bold`} disabled={submitting} data-dismiss="modal" onClick={onCancel}>
                                    <span className="fa fa-times"></span>{' '}Annuler
                                </button>
                                <button type="submit" className={`btn btn-round btn-success font-weight-bold`} disabled={submitting}>
                                    <span className="fa fa-save"></span>{' '}{submitting ? 'Encour...' : 'Soumettre'}
                                </button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
}

export default VerificationDemandDetails;