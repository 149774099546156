import React, {useEffect, useState} from 'react';
import FecthingSpinner from "../../Shared/FecthingSpinner";
import httpService from "../../../services/http.service";
import AppComment from "../../Shared/AppComment";

const UserComments = ({user, ...props}) => {

    const [fectching, setFectching] = useState(true);
    const [comments, setComments] = useState([]);
    const [perPage, setPerPage] = useState(null);

    const fetchComments = async (username, cb = () => null) => {
        const { data: response } = await httpService.get(`/users/${username}/comments`);
        setComments(response.data);
        setPerPage(response.meta.per_page);
        cb();
    }

    useEffect(() => {

        try {
            fetchComments(user.user_username, () => {
                setFectching(false);
            });
        } catch (e) {
            throw e;
        }

    }, []);

    if(fectching) {
        return <FecthingSpinner />
    }

    return (
        <div className="row clearfix">
            <div className="col-md-12">
                <div className="card">
                    <div className="header"><h2>{perPage} derniers commentaires</h2></div>
                    {comments.map(comment => (
                        <AppComment
                            user={user}
                            comment={comment}
                            key={comment.com_id}
                        />
                    ))}
                </div>
            </div>
        </div>
    )

}

export default UserComments;