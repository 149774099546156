import React from 'react';
import {getUserFullName} from './../../utils/index';
const MyOpinionLogo = require("../../assets/images/MyOpinion_Logo_Head.png");

const AppEventItem = ({event, ...props}) => {

    return (

        <li className="timeline-item">
            <div className="timeline-info">
                <span>{event?.event_date}</span>
            </div>
            <div className="timeline-marker"></div>
            <div className="timeline-content">
                <h3 className="timeline-title">{event?.event_title}</h3>
                <p className="text-muted mt-0 mb-0">Heure: {event?.event_time}, Lieu: {event?.event_localisation || '-'}</p>
                <p>{event?.event_descr}</p>
                <div id="lightgallery" className="row clearfix lightGallery">
                    {(event?.attachments || []).map(attachment => (
                        <div className="col-lg-3 col-md-6 m-b-30 m-r-5" key={attachment?.attach_id}>
                            <a className="light-link" href={attachment?.attach_url} target="_blank">
                                <img className="img-fluid rounded" src={attachment.attach_thumbnail || attachment?.attach_url} alt="" />
                            </a>
                        </div>
                    ))}
                </div>
                <ul className="list-unstyled team-info sm margin-0">
                    <li title={getUserFullName(event?.opinion?.user, true)}>
                        <img src={event?.opinion?.user?.profile?.prof_picture || MyOpinionLogo} alt="Avatar"/>
                    </li>
                    {event?.participants?.map(user => (
                        <li title={getUserFullName(user, true)}>
                            <img src={user?.profile?.prof_picture || MyOpinionLogo} alt="Avatar"/>
                        </li>
                    ))}
                    <li className="ml-2">
                        <span>{event?.participants?.length + 1} participation(s)</span>
                    </li>
                </ul>
            </div>
        </li>

    )

};

export default AppEventItem;