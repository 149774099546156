import React, { useEffect, useState } from 'react'
import AppBreadcrumb from '../../Shared/AppBreadcrumb'
import FecthingSpinner from '../../Shared/FecthingSpinner'
import httpService from "../../../services/http.service";
import MyOpinionLogo from "../../../assets/images/MyOpinion_Logo_Head.png";
import {
  extractResponseValidationErrors,
  formatCash, getUserFullName, numberWithSeparator,
  PERIODICITY_DAILY,
  PERIODICITY_MONTHLY,
  PERIODICITY_WEEKLY,
  PERIODICITY_YEARLY
} from "../../../utils/index";
import moment from 'moment/moment';
import { Badge, Col, Form, FormGroup } from "reactstrap";
import { useFormik } from 'formik';
import * as Yup from "yup";
import useAlert from '../../../hooks/useAlert';
import { serialize } from 'object-to-formdata';
import AppAlert from '../../Shared/AppAlert';
import AppInput from '../../Shared/AppInput';

const Monetization = ({ history, location, match, ...props }) => {

  const [data, setData] = useState([])
  const [fetching, setFetching] = useState(true)
  const [submitting, setSubmitting] = useState(false);
  const [setting, setSetting] = useState({});
  const [modal, setModal] = useState({ settingEditForm: false });

  const [alert, setAlert, onClose] = useAlert();

  const initialValues = {
    user_wallet_balance: 0,
  };


  const onEdit = () => {
    formik.setValues({
      ...initialValues,
      user_wallet_balance: Number(data?.official_account?.user_wallet_balance)?.toFixed(0) || 0,
    });
    setModal(state => ({ ...state, settingEditForm: true }));
  }

  const navigateToUserProfile = (user) => {
    history.push(`/users/${user?.user_username}`);
  }

  const fetchData = async (params = {}, cb = () => null) => {
    const { data: response } = await httpService.get(`/payments`, {
      params
    });
    setData(response.data);
    cb();
  };

  useEffect(() => {
    try {

      fetchData({}, () => {
        setFetching(false);
      });

    } catch (e) {
      throw e;
    }

  }, []);


  const onSubmit = async (values) => {

    setSubmitting(true);
    setAlert(null);

    const formData = serialize(values, {
      indices: true,
      booleansAsIntegers: true
    });

    try {

      const { data: response } = await httpService.post(`/payments/official-balance`, formData);
      setData(state => ({ ...state, official_account: response?.data }));

      onCancelEdit();

    }
    catch ({ response }) {
      const { data, status } = response;

      if (response && status === 422) {
        setAlert({
          type: 'danger',
          message: extractResponseValidationErrors(data)[0]
        })
      }
      else if (response && status === 500) {
        setAlert({
          type: 'danger',
          message: 'Erreur de traitement, veuillez contacter les administrateurs'
        })
      }
    }
    finally {
      setSubmitting(false);
    }

  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({
      user_wallet_balance: Yup.string().nullable(),
    }),
    onSubmit
  });

  const onCancelEdit = () => {
    formik.resetForm({ ...initialValues });
    setAlert(null);
    setModal(state => ({ ...state, settingEditForm: false }));
  };


  return (
    <div className="container-fluid">
      <AppBreadcrumb title="Monétisation" />
      {fetching ?
        <FecthingSpinner />
        :
        <div>

          <div className="col-md-12">
            <div className="card">
              {/* <div className="header"><h2>Autres indicateurs</h2></div> */}
              <div className="body">
                <div className="row text-center">
                  <div className="col-md-3 border-right pb-4 pt-4" title={numberWithSeparator(data?.totals?.total_wallet_balance)}>
                    <label className="mb-0">Total de coins à payer</label>
                    <h4 className="font-30 font-weight-bold text-col-blue text-success">{formatCash(data?.totals?.total_wallet_balance)} USD</h4>
                  </div>
                  <div className="col-md-3 border-right pb-4 pt-4" title={numberWithSeparator(data?.totals?.total_soutiens_count?.toFixed(0))}>
                    <label className="mb-0">Total de likes à payer</label>
                    <h4 className="font-30 font-weight-bold text-col-blue text-success">{formatCash(data?.totals?.total_soutiens_count)} USD</h4>
                  </div>
                  <div className="col-md-3 border-right pb-4 pt-4" title={numberWithSeparator(data?.totals?.cashout_user_coins)}>
                    <label className="mb-0">Total de coins encaissés</label>
                    <h4 className="font-30 font-weight-bold text-col-blue text-danger">{formatCash(data?.totals?.cashout_user_coins)} USD</h4>
                  </div>
                  <div className="col-md-3 pb-4 pt-4" title={numberWithSeparator(data?.totals?.cashout_user_likes?.toFixed(0))}>
                    <label className="mb-0">Total de likes encaissés</label>
                    <h4 className="font-30 font-weight-bold text-col-blue text-danger">{formatCash(data?.totals?.cashout_user_likes)} USD</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row clearfix">
            <div className="col-md-12">
              <div className="card">
                <div className="font-weight-normal"><h5>Compte officiel MyOpinion</h5></div>
                <div className="table-responsive">
                  <table className="table table-hover table-custom">
                    <thead>
                      <tr>
                        <th>Photo</th>
                        <th>Utilisateur</th>
                        <th>Coins</th>
                        <th>Opinions</th>
                        <th>Potes & Fans</th>
                        <th>Inscription</th>
                        <th>Dernière activité</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        // onClick={() => navigateToUserProfile(data?.official_account)} 
                        style={{ cursor: "pointer" }}>
                        <td className="w60">
                          <img
                            src={data?.official_account?.prof_picture || MyOpinionLogo}
                            data-toggle="tooltip"
                            data-placement="top" alt={`@(${data?.official_account?.user_username})`}
                            className="w35 rounded"
                            style={{ objectFit: 'cover' }}
                          />
                        </td>
                        <td>
                          {getUserFullName(data?.official_account, true)}{' '}
                          {data?.official_account.user_verified && (
                            <i style={{ cursor: 'pointer' }} title="Verified" className="fa fa-check-circle-o text-primary mr-1" />
                          )}
                          {data?.official_account.user_gold && (
                            <i style={{ cursor: 'pointer' }} title="Premium / Gold" className="fa fa-star text-warning mr-1" />
                          )}
                        </td>
                        <td className='text-success font-weight-bold'>
                          {Number(data?.official_account?.user_wallet_balance)?.toFixed(0)} Coins ({(data?.official_account?.user_wallet_balance * data?.totals?.coin_exchange_usd)?.toFixed(2)} USD)
                        </td>
                        <td>
                          {formatCash(data?.official_account?.opinions_count)}
                        </td>
                        <td>
                          <td className="text-center" >{formatCash(
                            data?.official_account?.friends_invited_count +
                            data?.official_account?.friends_accepted_count +
                            data?.official_account?.followers_count ||
                            0
                          )}
                          </td>
                        </td>
                        <td>
                          {moment(data?.official_account?.user_created_at).format('DD/MM/YYYY HH:mm')}
                        </td>
                        <td>
                          {moment(data?.official_account?.user_last_seen).format('DD/MM/YYYY HH:mm')}
                        </td>
                        <td>
                          <button className="btn btn-success text-white btn-sm mr-1 font-weight-bold" onClick={onEdit}>
                            <span>Modifier</span>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="row clearfix">
            <div className="col-md-12">
              <div className="card">
                <div className="font-weight-normal"><h5>Top 10 des utilisateurs {process.env.REACT_APP_APP_NAME} avec le plus de coins</h5></div>
                <div className="table-responsive">
                  <table className="table table-hover table-custom spacing8">
                    <thead>
                      <tr>
                        <th>Photo</th>
                        <th>Utilisateur</th>
                        <th>Coins</th>
                        <th>Opinions</th>
                        <th>Potes & Fans</th>
                        <th>Inscription</th>
                        <th>Dernière activité</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.top_wallet?.map(obj => (
                        <tr key={obj?.user_id} onClick={() => navigateToUserProfile(obj)} style={{ cursor: "pointer" }}>
                          <td className="w60">
                            <img
                              src={obj?.prof_picture || MyOpinionLogo}
                              data-toggle="tooltip"
                              data-placement="top" alt={`@(${obj?.user_username})`}
                              className="w35 rounded"
                              style={{ objectFit: 'cover' }}
                            />
                          </td>
                          <td>
                            {getUserFullName(obj, true)}{' '}
                            {obj.user_verified && (
                              <i style={{ cursor: 'pointer' }} title="Verified" className="fa fa-check-circle-o text-primary mr-1" />
                            )}
                            {obj.user_gold && (
                              <i style={{ cursor: 'pointer' }} title="Premium / Gold" className="fa fa-star text-warning mr-1" />
                            )}
                          </td>
                          <td className='text-success font-weight-bold'>
                            {Number(obj?.user_wallet_balance)?.toFixed(0)} Coins ({(obj?.user_wallet_balance * data?.totals?.coin_exchange_usd)?.toFixed(2)} USD)
                          </td>
                          <td>
                            {formatCash(obj?.opinions_count)}
                          </td>
                          <td>
                            <td className="text-center" >{formatCash(
                              obj?.friends_invited_count +
                              obj?.friends_accepted_count +
                              obj?.followers_count ||
                              0
                            )}
                            </td>
                          </td>
                          <td>
                            {moment(obj?.user_created_at).format('DD/MM/YYYY HH:mm')}
                          </td>
                          <td>
                            {moment(obj?.user_last_seen).format('DD/MM/YYYY HH:mm')}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="row clearfix">
            <div className="col-md-12">
              <div className="card">
                <div className="font-weight-normal"><h5>Top 10 des utilisateurs {process.env.REACT_APP_APP_NAME} avec le plus de likes</h5></div>
                <div className="table-responsive">
                  <table className="table table-hover table-custom spacing8">
                    <thead>
                      <tr>
                        <th>Photo</th>
                        <th>Utilisateur</th>
                        <th>Likes</th>
                        <th>Opinions</th>
                        <th>Potes & Fans</th>
                        <th>Inscription</th>
                        <th>Dernière activité</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.top_likes?.map(obj => (
                        <tr key={obj?.user_id} onClick={() => navigateToUserProfile(obj)} style={{ cursor: "pointer" }}>
                          <td className="w60">
                            <img
                              src={obj?.prof_picture || MyOpinionLogo}
                              data-toggle="tooltip"
                              data-placement="top" alt={`@(${obj?.user_username})`}
                              className="w35 rounded"
                              style={{ objectFit: 'cover' }}
                            />
                          </td>
                          <td>
                            {getUserFullName(obj, true)}{' '}
                            {obj.user_verified && (
                              <i style={{ cursor: 'pointer' }} title="Verified" className="fa fa-check-circle-o text-primary mr-1" />
                            )}
                            {obj.user_gold && (
                              <i style={{ cursor: 'pointer' }} title="Premium / Gold" className="fa fa-star text-warning mr-1" />
                            )}
                          </td>
                          <td className='text-info font-weight-bold'>
                            {obj?.sond_soutiens_count?.toFixed(0)} Likes ({(obj?.sond_soutiens_count * data?.totals?.like_exchange_usd)?.toFixed(2)} USD)
                          </td>
                          <td>
                            {formatCash(obj?.opinions_count)}
                          </td>
                          <td>
                            <td className="text-center" >{formatCash(
                              obj?.friends_invited_count +
                              obj?.friends_accepted_count +
                              obj?.followers_count ||
                              0
                            )}
                            </td>
                          </td>
                          <td>
                            {moment(obj?.user_created_at).format('DD/MM/YYYY HH:mm')}
                          </td>
                          <td>
                            {moment(obj?.user_last_seen).format('DD/MM/YYYY HH:mm')}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>




          {/* Modal edit official account */}
          <div className={`modal fade ${modal.settingEditForm ? 'd-block show' : ''}`} id="exampleModal" onClick={() => null}>
            <div className="modal-dialog modal-lg" role="document">
              <Form onSubmit={formik.handleSubmit}>
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Mise à jour du solde du compte MyOpinion</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCancelEdit}>
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body" style={{ maxHeight: '76vh', overflowY: 'auto' }}>
                    {alert && <AppAlert onClose={onClose} type={alert.type}>{alert.message}</AppAlert>}
                    <FormGroup>
                      <AppInput
                        label="Solde de MyOpinion (COIN)"
                        name="user_wallet_balance"
                        error={formik.errors.user_wallet_balance}
                        touched={formik.touched.user_wallet_balance}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.user_wallet_balance}
                        type="number"
                        step="1"
                      />
                    </FormGroup>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className={`btn btn-round btn-danger font-weight-bold`} disabled={submitting} data-dismiss="modal" onClick={onCancelEdit}>
                      <span className="fa fa-times"></span>{' '}Annuler
                    </button>
                    <button type="submit" className={`btn btn-round btn-success font-weight-bold`} disabled={submitting}>
                      <span className="fa fa-save"></span>{' '}{submitting ? 'Enregistrement...' : 'Enregistrer'}
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>

        </div>
      }
    </div>
  )
}

export default Monetization