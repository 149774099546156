import React, {forwardRef} from 'react';
import {Label} from "reactstrap";

const AppInputFile = forwardRef((forwardProps, ref) => {

    const {
        label,
        name,
        value,
        placeholder = 'Selectionnez',
        required = false,
        touched = null,
        error = null,
        onChange = () => null,
        onBlur = () => null,
        ...props
    } = forwardProps;

    return (
        <>
            {label && (<Label
                htmlFor={name}
                className="control-label">
                {label}
            </Label>)} {required && <span className="text-danger">*</span>}
            <div className="custom-file">
                <input
                    name={name}
                    type="file"
                    className="custom-file-input"
                    id={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                    {...props}
                />
                <label className="custom-file-label" htmlFor={name}>
                    {placeholder}
                </label>
            </div>
            {error && touched && (
                <em className="text-danger font-small italic font-weight-bold">{error}</em>
            )}
        </>
    );

})

export default AppInputFile;