import React, { useEffect, useState } from 'react';
import queryString from "query-string";
import AppBreadcrumb from "../../Shared/AppBreadcrumb";
import FecthingSpinner from "../../Shared/FecthingSpinner";
import httpService from "../../../services/http.service";
import AppComment from "../../Shared/AppComment";
import AppOpinion from "../../Shared/AppOpinion";
import AppPagination from "../../Shared/AppPagination";
import axios from 'axios';
import { Button } from 'reactstrap';

const ReportList = ({ history, location, match, ...props }) => {

    const query = queryString.parse(location.search);

    const initialValues = {
        keyword: query.keyword,
        page: query?.page || 1
    }

    const [fetching, setFetching] = useState(true);
    const [reports, setReports] = useState([]);
    const [reportProcess, setReportProcess] = useState(null);
    const [paginationData, setPaginationData] = useState({
        currentPage: query?.page || 1,
        lastPage: Infinity,
        hasNext: true,
        hasPrevious: false,
        totalItems: Infinity
    });

    const fetchReports = async (params = {}, cb = () => null) => {
        const { data: response } = await httpService.get(`/reports`, {
            params
        });
        console.log(response.data)
        setReports(response.data);
        setPaginationData(state => ({
            ...state,
            currentPage: response?.current_page,
            lastPage: response?.last_page,
            totalItems: response?.total,
            hasNext: !!response?.next_page_url,
            hasPrevious: !!response?.prev_page_url
        }));
        cb();
    }

    const handlePagePaginate = async (direction) => {

        let page = parseInt(query?.page || 1);

        const nextPage = direction === 'previous' ? --page : ++page;

        const data = {
            page: nextPage
        };

        history.push(`/reports?${queryString.stringify(data)}`);

    };

    useEffect(() => {
        setFetching(true);

        try {

            fetchReports({
                page: query?.page || 1
            }, () => {
                setFetching(false);
            });

        } catch (e) {
            throw e;
        }

    }, [
        query?.page
    ]);

    const handleAccept = async (id, sond_id, com_id) => {
        try {
            setReportProcess(id)
            const { data: response } = await httpService.post(`/reports/${id}/confirm`, {
                sond_id: sond_id,
                com_id: com_id
            });
            console.log(response)
            if (response?.success) {
                fetchReports()
            }
        } catch (error) {
            console.error('Erreur lors de la validation :', error);
            setReportProcess(null)
        }
    };

    const handleReject = async (id, sond_id, com_id) => {
        try {
            setReportProcess(id)
            const { data: response } = await httpService.post(`/reports/${id}/reject`, {
                sond_id: sond_id,
                com_id: com_id
            });
            console.log(response)
            if (response?.success) {
                fetchReports()
            }
        } catch (error) {
            console.error('Erreur lors du rejet :', error);
            setReportProcess(null)
        }
    };


    return (
        <div className="container-fluid">
            <AppBreadcrumb title={`Historique des ${process.env.REACT_APP_SOCIAL_HUB ? 'posts' : 'opinions'} et commentaires signalés`} />
            {fetching ? <FecthingSpinner /> : (
                <div className="row clearfix">
                    <div className="col-md-12">
                        {reports.map(report => {

                            const ReportDetails = () => (
                                <div className="card border-danger">
                                    <div className="body text-danger p-3">
                                        <small className="text-muted">Utilisateur: {report?.report_id}</small>
                                        <p className="mb-0">{report.reports[0].user.user_surname} {report.reports[0].user.user_name} (@{report.reports[0].user.user_username})</p>
                                        <small className="text-muted">Signalé le:</small>
                                        <p className="mb-0">{report.reports[0].report_date}</p>
                                        <small className="text-muted">Raisons:</small>
                                        <p className="mb-0">{report.reports[0].reasons.join(', ')}</p>
                                        <small className="text-muted">Description:</small>
                                        <p className="mb-0">{report.reports[0].descr || 'N/A'}</p>
                                        <small className="text-muted">Status:</small>
                                        <p className="mb-0 text-dark font-weight-bold">{report.reports[0].report_state || 'N/A'}</p>

                                        {(reportProcess != report?.reports[0]?.report_id && report?.reports[0]?.report_state == 'pending')  &&
                                            <div className="d-flex justify-content-around gap-2 mt-3">
                                                <Button variant="success" onClick={() => handleAccept(report?.reports[0]?.report_id, report?.sond_id, report?.com_id)} className='bg-success font-weight-bold border-0 mr-1'>
                                                    Accepter
                                                </Button>
                                                <Button variant="danger" onClick={() => handleReject(report?.reports[0]?.report_id, report?.sond_id, report?.com_id)} className='bg-danger font-weight-bold border-0 ml-1'>
                                                    Rejeter
                                                </Button>
                                            </div>
                                        }
                                    </div>


                                </div>
                            )

                            if (report.com_id) {
                                return (
                                    <div className="row">
                                        <div className="col-md-8">
                                            <AppComment
                                                user={report.user}
                                                comment={report}
                                                key={report.com_id}
                                                truncateLength={300}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <ReportDetails />
                                        </div>
                                    </div>

                                );
                            }
                            else if (report.sond_id) {
                                return (
                                    <div className="row">
                                        <div className="col-md-8">
                                            <div className="card mb-0">
                                                <div className="testimonials">
                                                    <AppOpinion
                                                        key={report.sond_id}
                                                        user={report?.user}
                                                        opinion={report}
                                                        truncateLength={350}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <ReportDetails />
                                        </div>
                                    </div>
                                );
                            }
                            else {
                                return null;
                            }

                        })}
                    </div>
                    <div className="col-md-12">
                        <div className="card">
                            <div className="body">
                                <AppPagination
                                    onNavigate={handlePagePaginate}
                                    currentPage={paginationData.currentPage}
                                    hasNext={paginationData.hasNext}
                                    hasPrevious={paginationData.hasPrevious}
                                    totalItems={paginationData.totalItems}
                                    lastPage={paginationData.lastPage}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ReportList;