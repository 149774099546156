import React, {useState, useEffect} from 'react';
import queryString from "query-string";
import {Link} from "react-router-dom";
import FecthingSpinner from "../Shared/FecthingSpinner";
import httpService from "../../services/http.service";
import {
    formatCash, getUserFullName, numberWithSeparator,
    PERIODICITY_DAILY,
    PERIODICITY_MONTHLY,
    PERIODICITY_WEEKLY,
    PERIODICITY_YEARLY
} from "../../utils/index";
import MyOpinionLogo from "../../assets/images/MyOpinion_Logo_Head.png";
import AppBreadcrumb from "../Shared/AppBreadcrumb";
import useAlert from "../../hooks/useAlert";
import AppAlert from "../Shared/AppAlert";
import {connect} from "react-redux";

const Dashboard = ({authUser, history, location, match, ...props}) => {

    const query = queryString.parse(location.search);

    const fetchActiveUsers = async (cb = () => null) => {
        const { data: response } = await httpService.get(`/stats/activeUsers`);
        cb(response);
        return response;
    }

    const fetchNewUsers = async (cb = () => null) => {
        const { data: response } = await httpService.get(`/stats/newUsers`);
        cb(response);
        return response;
    }

    const fetchTotalAuthorizationRequests = async (cb = () => null) => {
        const { data: response } = await httpService.get(`/stats/totalAuthorizationRequests`);
        cb(response);
        return response;
    }

    const fetchTotalUsers = async (cb = () => null) => {
        const { data: response } = await httpService.get(`/stats/totalUsers`);
        cb(response);
        return response;
    }

    const fetchActiveOpinions = async (cb = () => null) => {
        const { data: response } = await httpService.get(`/stats/activeOpinions`);
        cb(response);
        return response;
    }

    const fetchMostFollowedUsers = async (cb = () => null) => {
        const { data: response } = await httpService.get(`/stats/mostFollowedUser`);
        cb(response);
        return response;
    }

    const navigateToUserProfile = (user) => {
        history.push(`/users/${user?.user_username}`);
    }

    const initialValues = {}

    const [fetching, setFetching] = useState(true);
    const [activeUsers, setActiveUsers] = useState(null);
    const [newUsers, setNewUsers] = useState(null);
    const [activeOpinions, setActiveOpinions] = useState(0);
    const [totalUsers, setTotalUsers] = useState(0);
    const [mostFollowedUsers, setMostFollowedUsers] = useState([]);
    const [authorisationRequestsAlert, setAuthorisationRequestsAlert, onClose] = useAlert();

    const getVariationClassName = (value) => {
        if(value == 0) return '';
        if(value > 0) return 'text-success';
        if(value < 0) return 'text-danger';
    }

    const getVariationCaret = (value) => {
        if(value == 0) return null;
        if(value > 0) return <i className="fa fa-caret-up"></i>
        if(value < 0) return <i className="fa fa-caret-down"></i>
    }

    useEffect(() => {

        try {

            Promise.all([
                fetchActiveUsers(),
                fetchNewUsers(),
                fetchTotalUsers(),
                fetchActiveOpinions(),
                fetchMostFollowedUsers(),
                fetchTotalAuthorizationRequests(),
            ]).then((responses) => {
                setActiveUsers(responses[0]);
                setNewUsers(responses[1]);
                setTotalUsers(responses[2]);
                setActiveOpinions(responses[3]);
                setMostFollowedUsers(responses[4]);
                setAuthorisationRequestsAlert({
                    type: 'warning',
                    value: responses[5]
                });
                setFetching(false);
            });


        } catch (e) {
            throw e;
        }


    }, []);

    return (
        <>
            <div className="container-fluid">
                <AppBreadcrumb title="Tableau de bord"/>

                {fetching ? <FecthingSpinner /> : (
                    <>
                        {(process.env.REACT_APP_SOCIAL_HUB && (authUser?.isRoot || authUser.isAdmin)) && (
                            <div className="row clearfix">
                                <div className="col-md-12">
                                    {authorisationRequestsAlert?.value > 0 && (
                                        <AppAlert onClose={onClose} type={authorisationRequestsAlert?.type}>
                                            <Link className={`text-${authorisationRequestsAlert?.type}`}
                                                  to={`/authorization-requests`}>{`${authorisationRequestsAlert?.value} demande(s) d'autorisation en attente`}</Link>
                                        </AppAlert>
                                    )}
                                </div>
                            </div>
                        )}
                        <div className="row clearfix">

                            <div className="col-md-4">
                                <div className="card">
                                    <div className="header font-weight-bold"><h2>Nouveaux utilisateurs {process.env.REACT_APP_APP_NAME}</h2></div>
                                    <div className="body">
                                        <table className="table table-sm card-table mb-0">
                                            <tbody>
                                            <tr className="font-weight-bold">
                                                <td>Aujourd'hui</td>
                                                <td className="text-right">
                                                    {formatCash(newUsers[PERIODICITY_DAILY]['value'])}{' '}
                                                    <span className={getVariationClassName(newUsers[PERIODICITY_DAILY]['variation'])}>
                                                    ({newUsers[PERIODICITY_DAILY]['variation']}%)
                                                        {getVariationCaret(newUsers[PERIODICITY_DAILY]['variation'])}
                                                </span>
                                                </td>
                                            </tr>
                                            <tr className="font-weight-bold">
                                                <td>Cette semaine</td>
                                                <td className="text-right">
                                                    {formatCash(newUsers[PERIODICITY_WEEKLY]['value'])}{' '}
                                                    <span className={getVariationClassName(newUsers[PERIODICITY_WEEKLY]['variation'])}>
                                                    ({newUsers[PERIODICITY_WEEKLY]['variation']}%)
                                                        {getVariationCaret(newUsers[PERIODICITY_WEEKLY]['variation'])}
                                                </span>
                                                </td>
                                            </tr>
                                            <tr className="font-weight-bold">
                                                <td>Ce mois</td>
                                                <td className="text-right">
                                                    {formatCash(newUsers[PERIODICITY_MONTHLY]['value'])}{' '}
                                                    <span className={getVariationClassName(newUsers[PERIODICITY_MONTHLY]['variation'])}>
                                                    ({newUsers[PERIODICITY_MONTHLY]['variation']}%)
                                                        {getVariationCaret(newUsers[PERIODICITY_MONTHLY]['variation'])}
                                                </span>
                                                </td>
                                            </tr>
                                            <tr className="font-weight-bold">
                                                <td>Cette année</td>
                                                <td className="text-right">
                                                    {formatCash(newUsers[PERIODICITY_YEARLY]['value'])}{' '}
                                                    <span className={getVariationClassName(newUsers[PERIODICITY_YEARLY]['variation'])}>
                                                    ({newUsers[PERIODICITY_YEARLY]['variation']}%)
                                                        {getVariationCaret(newUsers[PERIODICITY_YEARLY]['variation'])}
                                                </span>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="card">
                                    <div className="header font-weight-bold"><h2>Utilisateurs {process.env.REACT_APP_APP_NAME} actifs</h2></div>
                                    <div className="body">
                                        <table className="table table-sm card-table mb-0">
                                            <tbody>
                                            <tr className="font-weight-bold">
                                                <td>Aujourd'hui</td>
                                                <td className="text-right">
                                                    {formatCash(activeUsers[PERIODICITY_DAILY]['value'])}{' '}
                                                    <span className={getVariationClassName(activeUsers[PERIODICITY_DAILY]['variation'])}>
                                                    ({activeUsers[PERIODICITY_DAILY]['variation']}%)
                                                        {getVariationCaret(activeUsers[PERIODICITY_DAILY]['variation'])}
                                                </span>
                                                </td>
                                            </tr>
                                            <tr className="font-weight-bold">
                                                <td>Cette semaine</td>
                                                <td className="text-right">
                                                    {formatCash(activeUsers[PERIODICITY_WEEKLY]['value'])}{' '}
                                                    <span className={getVariationClassName(activeUsers[PERIODICITY_WEEKLY]['variation'])}>
                                                    ({activeUsers[PERIODICITY_WEEKLY]['variation']}%)
                                                        {getVariationCaret(activeUsers[PERIODICITY_WEEKLY]['variation'])}
                                                </span>
                                                </td>
                                            </tr>
                                            <tr className="font-weight-bold">
                                                <td>Ce mois</td>
                                                <td className="text-right">
                                                    {formatCash(activeUsers[PERIODICITY_MONTHLY]['value'])}{' '}
                                                    <span className={getVariationClassName(activeUsers[PERIODICITY_MONTHLY]['variation'])}>
                                                    ({activeUsers[PERIODICITY_MONTHLY]['variation']}%)
                                                        {getVariationCaret(activeUsers[PERIODICITY_MONTHLY]['variation'])}
                                                </span>
                                                </td>
                                            </tr>
                                            <tr className="font-weight-bold">
                                                <td>Cette année</td>
                                                <td className="text-right">
                                                    {formatCash(activeUsers[PERIODICITY_YEARLY]['value'])}{' '}
                                                    <span className={getVariationClassName(activeUsers[PERIODICITY_YEARLY]['variation'])}>
                                                    ({activeUsers[PERIODICITY_YEARLY]['variation']}%)
                                                        {getVariationCaret(activeUsers[PERIODICITY_YEARLY]['variation'])}
                                                </span>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="card">
                                    <div className="header"><h2>Autres indicateurs</h2></div>
                                    <div className="body">
                                        <div className="row text-center">
                                            <div className="col-6 border-right pb-4 pt-4" title={numberWithSeparator(totalUsers)}>
                                                <label className="mb-0">Utilisateurs</label>
                                                <h4 className="font-30 font-weight-bold text-col-blue">{formatCash(totalUsers)}</h4>
                                            </div>
                                            <div className="col-6 pb-4 pt-4" title={numberWithSeparator(activeOpinions)}>
                                                <label className="mb-0">{process.env.REACT_APP_SOCIAL_HUB ? `Posts actifs` : `Opinions actives`}</label>
                                                <h4 className="font-30 font-weight-bold text-col-blue">{formatCash(activeOpinions)}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="row clearfix">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="header font-weight-bold"><h2>Top 10 des utilisateurs {process.env.REACT_APP_APP_NAME} les plus suivis</h2></div>
                                    <div className="table-responsive">
                                        <table className="table table-hover table-custom spacing8">
                                            <thead>
                                            <tr>
                                                <th>Photo</th>
                                                <th>Utilisateur</th>
                                                <th>Pays</th>
                                                <th className="text-center">
                                                    {process.env.REACT_APP_SOCIAL_HUB ? 'Contacts & Abonnés' : 'Potes & Fans'}
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {mostFollowedUsers.map(obj => (
                                                <tr key={obj?.user_id} onClick={() => navigateToUserProfile(obj)} style={{cursor: "pointer"}}>
                                                    <td className="w60">
                                                        <img
                                                            src={obj?.prof_picture || MyOpinionLogo}
                                                            data-toggle="tooltip"
                                                            data-placement="top" alt={`@(${obj?.user_username})`}
                                                            className="w35 rounded"/>
                                                    </td>
                                                    <td>
                                                        {getUserFullName(obj, true)}{' '}
                                                        {obj.user_verified && (
                                                            <i style={{cursor: 'pointer'}} title="Verified" className="fa fa-check-circle-o text-primary mr-1"/>
                                                        )}
                                                        {obj.user_gold && (
                                                            <i style={{cursor: 'pointer'}} title="Premium / Gold" className="fa fa-star text-warning mr-1"/>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {(obj?.ip_country_flag && obj?.ip_country_name) && (
                                                            <>
                                                                <img
                                                                    src={obj?.ip_country_flag}
                                                                    data-toggle="tooltip"
                                                                    data-placement="top" alt={obj?.ip_country_name}
                                                                    className="w20 rounded"/>
                                                                {' '}
                                                            </>
                                                        )}
                                                        {obj?.ip_country_name || '-'}
                                                    </td>
                                                    <td className="text-center" title={numberWithSeparator(obj?.total_followers || 0)}>{formatCash(obj?.total_followers || 0)}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>

                )}
            </div>
        </>
    );

}

const mapStateToProps = (state) => ({
    authUser: state.auth.user
})

const mapDispatchToProps = (dispatch) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
