import {useState} from "react";

const useAlert = () => {
    const [alert, setAlert] = useState(null);

    const onClose = () => {
        setAlert(null);
    }

    return [alert, setAlert, onClose];
}

export default useAlert;