import React, {useEffect} from 'react';
import {Link} from "react-router-dom";

const AppPagination = ({
                           currentPage = 1,
                           totalItems = Infinity,
                           lastPage = Infinity,
                           hasNext = true,
                           hasPrevious = false,
                           onNavigate = () => null,
                           ...props
                       }) => {


    return (
        <nav aria-label="Page navigation example" className="d-flex" style={{justifyContent: "space-between", alignItems: "center"}}>
            <span className="font-weight-bold font-14">Page: { currentPage } sur { lastPage }</span>
            <ul className="pagination m-0">
                <li className="page-item mr-1">
                    <button onClick={() => hasPrevious ? onNavigate('previous') : null} disabled={!hasPrevious} className="btn btn-info font-weight-bold">
                        <i className="fa fa-step-backward"></i> Precedent
                    </button>
                </li>
                <li className="page-item">
                    <button onClick={() => hasNext ? onNavigate('next') : null} disabled={!hasNext} className="btn btn-info font-weight-bold">
                        Suivant <i className="fa fa-step-forward"></i>
                    </button>
                </li>
            </ul>
        </nav>
    );

};

export default AppPagination;