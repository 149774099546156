import React from 'react';
import UserCardBody from "./Body";
import UserCardFooter from "./Footer";

const UserCard = ({user}) => {
    return (
        <div className="card c_grid c_blue border shadow-sm">
            <UserCardBody user={user} className="card-body text-center" />
            <UserCardFooter user={user} />
        </div>
    )
};

export default UserCard;