import axios from "axios";
import * as jwtService from './jwt.service';

const baseURL = process.env.REACT_APP_API_ENDPOINT;

const jwt = jwtService.get();

const config = {
    baseURL,
}

if(jwt) {
    config['headers'] = {
        Authorization: `Bearer ${jwt?.access_token}`
    }
}

const instance = axios.create(config);

instance.interceptors.response.use(
    response => response,
    error => {
        if(error.response.status == 401 && window.location.pathname !== '/login') {
            return jwtService.remove(() => {
                window.location.replace('/login');
            });
        }

        return Promise.reject(error);
    });

export default instance;