import React, {useState, useEffect} from 'react';
import httpService from "../../../services/http.service";
import AppBreadcrumb from "../../Shared/AppBreadcrumb";
import FecthingSpinner from "../../Shared/FecthingSpinner";
import AppComment from "../../Shared/AppComment";
import {useFormik} from "formik";
import AppInput from "../../Shared/AppInput";
import AppPagination from "../../Shared/AppPagination";
const queryString = require('query-string');

const CommentList = ({history, location, match, ...props}) => {

    const query = queryString.parse(location.search);

    const [fetching, setFetching] = useState(true);
    const [comments, setComments] = useState([]);
    const [paginationData, setPaginationData] = useState({
        currentPage:  query?.page || 1,
        lastPage: Infinity,
        hasNext: true,
        hasPrevious: false,
        totalItems: Infinity
    });

    const initialValues = {
        com_id: query.com_id || '',
        keyword: query.keyword || '',
        page: query?.page || 1
    };

    const fetchComments = async (params = {}, cb = () => null) => {
        const { data: response } = await httpService.get(`/comments`, {
            params
        });
        setComments(response.data);
        setPaginationData(state => ({
            ...state,
            currentPage: response?.meta?.current_page,
            lastPage: response?.meta?.last_page,
            totalItems: response?.meta?.total,
            hasNext: !!response?.links?.next,
            hasPrevious: !!response?.links?.prev
        }));
        cb();
    };

    const onSubmit = async (values) => {
        setFetching(true);

        const data = {
            ...initialValues,
            ...values,
            page: 1
        };

        history.push(`/comments?${queryString.stringify(data)}`);
    }

    const onReset = () => {
        formik.resetForm({
            com_id: '',
            keyword: '',
            page: 1,
        });

        history.push(`/comments`);
    }

    const handlePagePaginate = async (direction) => {

        let page = parseInt(query?.page || 1);

        const nextPage = direction === 'previous' ? --page : ++page;

        const data = {
            com_id: query.com_id || '',
            keyword: query.keyword || '',
            page: nextPage
        };

        history.push(`/comments?${queryString.stringify(data)}`);

    }

    useEffect(() => {
        setFetching(true);

        try {

            fetchComments({
                com_id: query.com_id || '',
                keyword: query.keyword || '',
                page: query?.page || 1
            }, () => {
                setFetching(false);
            });

        } catch (e) {
            throw e;
        }
    }, [
        query.com_id,
        query.keyword,
        query?.page,
    ]);

    const formik = useFormik({
        initialValues,
        onSubmit
    });

    return (
        <div className="container-fluid">
            <AppBreadcrumb title="Historique de commentaires" />

            <div className="row clearfix">
                <div className="col-md-12">
                    <div className="card border shadow-sm">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="card-header bg-info text-white font-weight-bold">
                                <i className="fa fa-search"></i>{' '}
                                <span>Filtre de recherche</span>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <AppInput
                                            name="com_id"
                                            placeholder="Identifiant"
                                            value={formik.values.com_id}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <AppInput
                                            name="keyword"
                                            placeholder="Mot clé"
                                            value={formik.values.keyword}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer bg-white text-right">
                                <button type="button" onClick={onReset} className="btn btn-danger mr-1">
                                    <i className="fa fa-times"></i>{' '}
                                    <span className="font-weight-bold">Annuler</span>
                                </button>
                                <button type="submit" className="btn btn-success">
                                    <i className="fa fa-check"></i>{' '}
                                    <span className="font-weight-bold">Rechercher</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {fetching ? <FecthingSpinner /> : (
                <div className="row clearfix">
                    <div className="col-md-12">
                        {comments.map(comment => (
                            <AppComment
                                user={comment.user}
                                comment={comment}
                                key={comment.com_id}
                                truncateLength={300}
                            />
                        ))}
                    </div>
                    <div className="col-md-12">
                        <div className="card">
                            <div className="body">
                                <AppPagination
                                    onNavigate={handlePagePaginate}
                                    currentPage={paginationData.currentPage}
                                    hasNext={paginationData.hasNext}
                                    hasPrevious={paginationData.hasPrevious}
                                    totalItems={paginationData.totalItems}
                                    lastPage={paginationData.lastPage}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default CommentList;