export const loggedIn = (data) => dispatch => {
    dispatch({
        type: 'LOGGED_IN',
        payload: data
    });
}

export const loggedOut = (data) => dispatch => {
    dispatch({
        type: 'LOGGED_OUT'
    });
}

export const profileUpdated = (data) => dispatch => {
    dispatch({
        type: 'PROFILE_UPDATED',
        payload: data
    })
}