import React from "react";

const AppBreadcrumb = ({title, children, ...props}) => {
    return (
        <div className="block-header">
            <div className="row clearfix">
                <div className="col-md-6 col-sm-12">
                    <h2>{process.env.REACT_APP_APP_NAME}</h2>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item active" aria-current="page">{title}</li>
                        </ol>
                    </nav>
                </div>
                {children}
            </div>
        </div>
    )
}

export default AppBreadcrumb;