import React from "react";

const UserCardFooter = ({user}) => {
    return (
        <div className="card-footer bg-light text-center">
            <div className="row clearfix">
                <div className="col-3 font-16">
                    <i className="fa fa-group"></i>
                    <div>
                        <span className="text-muted">{(user.friends_invited_count || 0) + (user.friends_accepted_count || 0)} {process.env.REACT_APP_SOCIAL_HUB ? "Contacts" : "Potes"}</span>
                    </div>
                </div>
                <div className="col-2 font-16">
                    <i className="fa fa-star"></i>
                    <div>
                        <span className="text-muted">{user.followers_count || 0} {process.env.REACT_APP_SOCIAL_HUB ? "Abonnés" : "Fans"}</span>
                    </div>
                </div>
                <div className="col-2 font-16">
                    <i className="fa fa-tags"></i>
                    <div>
                        <span className="text-muted">{user.followed_count || 0} {process.env.REACT_APP_SOCIAL_HUB ? "Abonnements" : "Fan de"}</span>
                    </div>
                </div>
                <div className="col-2 font-16">
                    <i className="fa fa-thumbs-up"></i>
                    <div>
                        <span className="text-muted">{user.soutiens_count || 0} Réactions</span>
                    </div>
                </div>
                <div className="col-3 font-16">
                    <i className="fa fa-pencil"></i>
                    <div>
                        <span className="text-muted">{user.opinions_count || 0} {process.env.REACT_APP_SOCIAL_HUB ? "Posts" : "Opinions"}</span>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default UserCardFooter;