import React from 'react';
const classnames = require("classnames");
const MyOpinionLogo = require("../../assets/images/MyOpinion_Logo_Head.png");
const {Link} = require("react-router-dom");

const AppUser = ({user}) => (
    <div className="card c_grid c_blue border shadow-sm">
        <div className="body text-center">
            <div>
                <img
                    loading="lazy"
                    className={classnames({'rounded-circle' : !!user?.profile?.prof_picture})}
                    height="128px"
                    width="128px"
                    src={user?.profile?.prof_picture || MyOpinionLogo} alt="" />
            </div>
            <h6 className="mt-3 mb-0">
                {`${user?.user_surname} ${user?.user_name}`}{' '}
                {user?.user_verified && (<span className="badge badge-primary" title="Compte certifie">
                                            <i className="fa fa-check"></i>
                                        </span>)}
            </h6>
            <span>{user?.user_email}</span>
            <br/>
            <span className="font-italic font-weight-bold font-12">
                                        @{user?.user_username}{' '}{user?.user_muted ? (
                <span className="badge badge-danger" title="Mute">
                                            <i className="fa fa-volume-off"></i>
                                        </span>
            ) : null}
                                    </span>
            <div className="mt-2">
                <Link to={`/users/${user?.user_username}`}>
                    <button className="btn btn-primary btn-sm">
                        <i className="fa fa-eye"></i>{' '}
                        <span className="font-weight-bold">Voir le profil</span>
                    </button>
                </Link>
            </div>
        </div>
        <div className="card-footer bg-light text-center">
            <div className="row clearfix">
                <div className="col-3 font-16">
                    <i className="fa fa-group"></i>
                    <div>
                        <span className="text-muted">{(user.friends_invited_count || 0) + (user.friends_accepted_count || 0)} Potes</span>
                    </div>
                </div>
                <div className="col-2 font-16">
                    <i className="fa fa-star"></i>
                    <div>
                        <span className="text-muted">{user.followers_count || 0} fans</span>
                    </div>
                </div>
                <div className="col-2 font-16">
                    <i className="fa fa-tags"></i>
                    <div>
                        <span className="text-muted">{user.followed_count || 0} fan de</span>
                    </div>
                </div>
                <div className="col-2 font-16">
                    <i className="fa fa-thumbs-up"></i>
                    <div>
                        <span className="text-muted">{user.soutiens_count || 0} Jaimes</span>
                    </div>
                </div>
                <div className="col-3 font-16">
                    <i className="fa fa-pencil"></i>
                    <div>
                        <span className="text-muted">{user.opinions_count || 0} Opinions</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default AppUser;