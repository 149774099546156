import React, {useEffect, useState} from 'react';
import AppBreadcrumb from "../../Shared/AppBreadcrumb";
import {Link} from "react-router-dom";
import FecthingSpinner from "../../Shared/FecthingSpinner";
import axios from "axios";
import httpService from "../../../services/http.service";
import {generateColor, getRandomColor} from '../../../utils/index';
import ReactApexChart from "react-apexcharts";

const UserBadgeStats = ({history, match, location, ...props}) => {

    const [fetching, setFetching] = useState(true);
    const [charts, setCharts] = useState([]);

    const fetchBadgesStats = async (cb = () => null) => {

        const responses = await axios.all([
            httpService.get(`/charts/userByBadge?badge_type=user_badge_goal`),
            httpService.get(`/charts/userByBadge?badge_type=user_badge_business`),
            httpService.get(`/charts/userByBadge?badge_type=user_badge_hobby`),
            httpService.get(`/charts/userByBadge?badge_type=user_badge_food`),
            httpService.get(`/charts/userByBadge?badge_type=user_badge_color`),
        ]);

        const stats = responses.map(({data}, index) => {

            let badge_type = '';

            switch (data[0]['badge_type']) {

                case 'user_badge_goal':
                    badge_type = 'Objectif de vie';
                    break;

                case 'user_badge_business':
                    badge_type = 'Travail';
                    break;

                case 'user_badge_hobby':
                    badge_type = 'Loisirs';
                    break;

                case 'user_badge_food':
                    badge_type = 'Nourritures';
                    break;

                case 'user_badge_color':
                    badge_type = 'Couleurs';
                    break;

            }

            const total = data.reduce((prev, curr) => curr['badge_users'] + prev, 0);

            return {
                series: data ? data.map(obj => obj['pourcentage']) : [],
                options: {
                    yaxis: {
                        labels: {
                            formatter: function (value, {seriesIndex, config}) {
                                return `${Number.parseFloat(value).toFixed(2)} %`;
                            }
                        },
                    },
                    title: {
                        text: `Répartition des utilisateurs par badge de type "${badge_type}"`,
                        align: 'center',
                        margin: 5,
                        offsetX: 0,
                        offsetY: 0,
                        floating: false,
                        style: {
                            fontSize:  '14px',
                            fontWeight:  'bold',
                            fontFamily:  undefined,
                            color:  '#003e6f'
                        },
                    },
                    dataLabels: {
                        enabled: true,
                        formatter: function (val, opt) {
                            return `${opt.w.config.labels[opt.seriesIndex]} : ${Number.parseFloat(val).toFixed(2)} %`
                        },
                        dropShadow: {}
                    },
                    chart: {
                        width: "100%",
                        type: 'pie'
                    },
                    colors: data ? data.map(obj => getRandomColor()) : [],
                    labels: data ? data.map(obj => obj['badge_value']) : [],
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }],
                    legend: {
                        position: 'bottom',
                        markers: {
                            radius: 0
                        }
                    }
                }
            }

        });

        setCharts(stats);

        cb();

    }

    useEffect(() => {

        try {

            fetchBadgesStats(() => {

                setFetching(false);
            });

        } catch (e) {
            throw e;
        }

    }, []);

    if(fetching) {
        return <FecthingSpinner />
    }

    return (
        <div className="container-fluid">
            <AppBreadcrumb title="Statistiques utilisateurs (Badges)" />

            <div className="row clearfix">
                {charts.map((chart, index) => (
                    <div className="col-lg-6 col-md-12" key={index}>
                        <div className="card">
                            <div className="body">
                                <ReactApexChart options={chart.options} series={chart.series} type="pie"  />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )

};

export default UserBadgeStats;